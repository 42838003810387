import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Component/Pages/Home";
import Header from "./Component/navbar/Header";
import Footer from "./Component/navbar/Footer";
import Contactus from "./Component/Pages/Contactus";
import About from "./Component/Pages/About";
import Service from "./Component/Pages/Service";
import DonationPage from "./Component/Pages/Donationpage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Service" element={<Service />}></Route>
          <Route path="/Contactus" element={<Contactus />}></Route>
          <Route path="/DonationPage" element={<DonationPage />}></Route>


        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
