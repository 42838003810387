import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="container-fluid topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block">
        <div className="container">
          <div className="row gx-0 align-items-center">
            <div className="col-lg-8 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <div className="border-end border-primary pe-3">
                <Link to="tel:+91 9869516206">
                <i className="fa fa-phone-alt fa-2x" style={{fontSize:"14px"}}></i>
                      <span class="" style={{color:"rgb(108, 117, 125)"}}>+91 9869516206</span>
                    </Link>
                </div>
                <div className="ps-3">
                  <Link to="mailto:jaaikaran@weplayfortheworld.org" className="text-muted small">
                    <i className="fas fa-envelope text-primary me-2"></i>
                    jaaikaran@weplayfortheworld.org
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <div className="d-flex justify-content-end">
                <div className="d-flex border-end border-primary pe-3">
                  
                  <Link className="btn p-0 text-primary me-3" to="https://www.youtube.com/@Weplayfortheworld" target="blank">
                    <i className="fab fa-youtube"></i>
                  </Link>
                  <Link className="btn p-0 text-primary me-3" to="https://www.instagram.com/weplayfortheworld/?igsh=MW5lamdjMGczNTNs" target="blank">
                    <i className="fab fa-instagram"></i>
                  </Link>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid nav-bar px-0 px-lg-4 py-lg-0 ${isSticky ? 'sticky' : ''}`}>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" className="navbar-brand p-0">
              <h1 className="text-primary mb-0">
                {/* <i className="fab fa-slack me-2"></i>  */}
                <img src="/assets/img/logo.png" style={{objectFit:'cover'}}></img>
                Charity
              </h1>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-0 mx-lg-auto">
                <Link to="/" className="nav-item nav-link active">
                  Home
                </Link>
                <Link to="/About" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/Service" className="nav-item nav-link">
                  Services
                </Link>
                {/* <Link to="/Service" className="nav-item nav-link">
                  Testimonial
                </Link> */}
                <Link to="/Contactus" className="nav-item nav-link">
                  Contact
                </Link>
                <div className="nav-btn px-3">
                  
                  <Link to="/DonationPage" className="btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0">
                    Donate
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

    
    </div>
  );
};

export default Header;
