import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const DonationPage = () => {
  const [amount, setAmount] = useState(10.0);

  const getParsedAmount = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0.0 : parsedValue.toFixed(2);
  };

  const handleAmountChange = (e) => {
    const newAmount = getParsedAmount(e.target.value);
    setAmount(newAmount);
  };

  const handleButtonClick = (value) => {
    const newAmount = getParsedAmount(value);
    setAmount(newAmount);
  };

  useEffect(() => {
    setAmount(getParsedAmount(amount));
  }, [amount]);

//   const donateLink = `https://www.paypal.me/webevasion/${amount}/`;

  return (
    <>    <div>
       <div className="container-fluid bg-breadcrumb">
        <div
          className="container text-center py-5"
          style={{ maxWidth: "900px" }}
        >
          <h4
            className="text-white display-4 mb-4 wow fadeInDown"
            data-wow-delay="0.1s"
          >
            Our Services
          </h4>
          <ol
            className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
            data-wow-delay="0.3s"
          >
            <li className="breadcrumb-item">
              <Link href="index.html">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link href="#">Pages</Link>
            </li>
            <li className="breadcrumb-item active text-primary">Service</li>
          </ol>
        </div>
      </div>
      
    </div>
   <div className="donate-page">
      
      <div className="donation_wrapper">
        <div className="title">Make a donation</div>
        <div className="single_amount_wrapper">
          <div className="single_amount" onClick={() => handleButtonClick(5)}>
            5$
          </div>
          <div className="single_amount" onClick={() => handleButtonClick(10)}>
            10$
          </div>
          <div className="single_amount" onClick={() => handleButtonClick(15)}>
            15$
          </div>
          <div className="single_amount" onClick={() => handleButtonClick(20)}>
            20$
          </div>
        </div>
        <div className="amount_wrapper">
          <input
            type="number"
            value={amount}
            step="0.1"
            min="1.00"
            onChange={handleAmountChange}
          />
          <div className="suffix">$</div>
        </div>
        {/* <Link to={donateLink} target="_blank" rel="nofollow">
          Donate with paypal.me
        </Link> */}
        <Link to="#" rel="nofollow">
          Donate Us
        </Link>
      </div>
    </div>
    </>

  );
};

export default DonationPage;