import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div className="container-fluid bg-breadcrumb">
        <div
          className="container text-center py-5"
          style={{ maxWidth: "900px" }}
        >
          <h4
            className="text-white display-4 mb-4 wow fadeInDown"
            data-wow-delay="0.1s"
          >
            About Us
          </h4>
          <ol
            className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
            data-wow-delay="0.3s"
          >
            <li className="breadcrumb-item">
              <Link to="index.html">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Pages</Link>
            </li>
            <li className="breadcrumb-item active text-primary">About</li>
          </ol>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid bg-light about py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="about-item-content bg-white rounded p-5 h-100">
                <h4 className="text-primary">About Our Company</h4>
                <h1 className="display-4 mb-4">
                  who we are?
                </h1>
                <p>
                Welcome to the Play for co, where compassion meets action. We are a non-profit organization dedicated to making a positive impact on the lives of children in need. Our foundation is built on the belief that every child deserves a chance to thrive, regardless of their circumstances.
                </p>
                <p>
                Our vision is a world where every child has access to the resources they need to grow, learn, and succeed. We aim to break the cycle of poverty and provide opportunities for a brighter future.
                </p>
                <p className="text-dark">
                  <i className="fa fa-check text-primary me-3"></i>Ensuring children's health and well-being.
                </p>
                <p className="text-dark">
                  <i className="fa fa-check text-primary me-3"></i>Providing safe shelters and support.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i>Empowering children through quality education.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i> Combating child hunger and malnutrition.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i>Offering emergency aid and relief.
                </p>
                
              </div>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
              <div className="bg-white rounded p-5 h-100">
                <div className="row g-4 justify-content-center">
                  <div className="col-12">
                    <div className="rounded about-img bg-light">
                      <img
                        src="/assets/img/about/about-1.jpg"
                        className="img-fluid rounded w-100"
                        alt="About"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span
                          className="text-primary fs-2 fw-bold"
                          data-toggle="counter-up"
                        >
                          1000
                        </span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Peoples Donated</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span
                          className="text-primary fs-2 fw-bold"
                          data-toggle="counter-up"
                        >
                          5000
                        </span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Children Benefited</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span
                          className="text-primary fs-2 fw-bold"
                          data-toggle="counter-up"
                        >
                          556
                        </span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Dedicated Volunteers</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span
                          className="text-primary fs-2 fw-bold"
                          data-toggle="counter-up"
                        >
                          100
                        </span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Team Members</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

     
    </div>
  );
};

export default About;
