import React from "react";
import { Link } from "react-router-dom";


const Service = () => {
  return (
    <div>
      <div className="container-fluid bg-breadcrumb">
        <div
          className="container text-center py-5"
          style={{ maxWidth: "900px" }}
        >
          <h4
            className="text-white display-4 mb-4 wow fadeInDown"
            data-wow-delay="0.1s"
          >
            Our Services
          </h4>
          <ol
            className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown"
            data-wow-delay="0.3s"
          >
            <li className="breadcrumb-item">
              <Link href="index.html">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link href="#">Pages</Link>
            </li>
            <li className="breadcrumb-item active text-primary">Service</li>
          </ol>
        </div>
      </div>
      {/* Header End */}

      {/* Service Start */}
      <div className="container-fluid service py-3">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-1 wow fadeInUp service-main"
            data-wow-delay="0.2s"
          >
            <h4 className="text-primary">Our Services</h4>
            <h1 className="display-4 mb-4">We Provide Best Services</h1>
           
          </div>
          
        </div>
      </div>
      {/* Service End */}

     <div className="service-containers row mb-5 d-flex justify-content-center">
      <div className="col-lg-6">
       <img 
       className="service-img-1"
       src="/assets/img/servicepdfimg.png"  style={{objectFit:"contain"}}/>
      </div>
      <div className="col-lg-6 service-content-1">
       <p>
       Education Programs: We provide scholarships, school supplies, and educational resources to ensure that every child has the opportunity to receive a quality education. Our programs include tutoring, mentoring, and after-school activities to support academic success.
Healthcare Initiatives: Access to healthcare is a fundamental right. We offer medical care, vaccinations, and health education to keep children healthy and thriving. Our mobile clinics reach remote areas, providing essential medical services to underserved communities.
Nutritional Support: We combat child hunger through our feeding programs, ensuring that children receive nutritious meals essential for their growth and development. Our community gardens and nutrition workshops teach families how to sustain a healthy diet.
Safe Havens: We provide safe shelters for homeless and at-risk children, offering a secure environment where they can feel protected and supported. Our shelters offer counseling and life skills training to help children overcome trauma and build resilience.
Emergency Relief: In times of crisis, we are on the front lines, providing immediate assistance to children affected by natural disasters, conflicts, and other emergencies. Our rapid response teams deliver food, water, medical aid, and emotional support to those in need.
Emergency Relief: In times of crisis, we are on the front lines, providing immediate assistance to children affected by natural disasters, conflicts, and other emergencies.
       </p>
      </div>

     </div>

     <div className="container-fluid service py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp home-heading"
            data-wow-delay="0.2s"
          >
            {/* <h4 className="text-primary">Donate, for every child</h4> */}
            <h1 className="display-4 mb-4">Donate, to make a change</h1>
           <Link to="/donate"> <button className="btn btn-primary">Donate Now</button> </Link>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/service/service-1.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-users fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    Your generous donations directly support our initiatives aimed at providing essential resources and opportunities for underprivileged children.
                    </Link>
                    {/* <p className="mb-4">
                      Famine confirmed in Sudan’s North Darfur, confirming UN
                      agencies worst fears
                    </p> */}
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/service/service-2.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-hospital fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    We develop and implement community-based programs that support children’s overall well-being, including mentoring.
                    </Link>
                    {/* <p className="mb-4">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Perspiciatis, eum!
                    </p> */}
                  
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/service/service-3.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-car fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home  h4 mb-4">
                    In times of crisis, your support enables us to offer immediate aid to children affected by natural disasters, conflicts, and other emergencies
                    </Link>
                    {/* <p className="mb-4">
                      Conflict in Sudan is pushing already vulnerable children
                      deeper into hardship
                    </p> */}
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/service/service-4.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-home fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    Donations help us build and maintain shelters for homeless children, providing them with a safe and nurturing environment.
                    </Link>
                    
                
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
