import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid footer py-5 wow fadeIn"
        data-wow-delay="0.2s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-xl-8">
              <div className="mb-5">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-6 col-xl-8">
                    <div className="footer-item">
                      <Link to="index.html" className="p-0">
                        <h3 className="text-white">
                        <img src="/assets/img/logo.png"></img> Charity
                        </h3>
                        {/* <img src="/assets/img/logo.png" alt="Logo" /> */}
                      </Link>
                      <p className="text-white mb-4">
                      We Play for the World transforms the passion for sport into positive change. 🤝
                      Every kit purchase supports charities around the world.
                      </p>
                      <div className="footer-btn d-flex">
                        
                        <Link
                          className="btn btn-md-square rounded-circle me-3"
                          to="https://www.youtube.com/@Weplayfortheworld" target="blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </Link>
                        <Link
                          className="btn btn-md-square rounded-circle me-3"
                          to="https://www.instagram.com/weplayfortheworld/?igsh=MW5lamdjMGczNTNs" target="blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </Link>
                      
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-4">
                    <div className="footer-item">
                      <h4 className="text-white mb-4">Useful Links</h4>
                      <Link to="/About">
                        <i className="fas fa-angle-right me-2"></i> About Us
                      </Link>
                      <Link to="/Service">
                        <i className="fas fa-angle-right me-2"></i> Services
                      </Link>
                      <Link to="/Contactus">
                        <i className="fas fa-angle-right me-2"></i> Contact
                      </Link>
                      <Link to="/DonationPage">
                        <i className="fas fa-angle-right me-2"></i> Donate
                      </Link>
                      
                    </div>
                  </div>
                 
                </div>
              </div>
            
            </div>

            <div className="col-xl-4">
              <div className="footer-item">
                <h4 className="text-white mb-4">Newsletter</h4>
                
                <div className="position-relative rounded-pill mb-4">
                  <input
                    className="form-control rounded-pill w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2"
                  >
                    Subscribe
                  </button>
                </div>
                <div class="d-flex flex-shrink-0">
                  <div class="footer-btn">
                    <Link
                      to="#"
                      class="btn btn-lg-square rounded-circle position-relative wow tada"
                      data-wow-delay=".9s"
                    >
                      <i class="fa fa-phone-alt fa-2x"></i>
                      <div
                        class="position-absolute"
                        // style="top: 2px; right: 12px;"
                        style={{ top: "2px", right: "12px" }}
                      >
                        <span>
                          <i class="fa fa-comment-dots text-secondary"></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div class="d-flex flex-column ms-3 flex-shrink-0">
                    <span>Call to Our Experts</span>
                    <Link to="tel:+91 9869516206">
                      <span class="text-white">+91 9869516206</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
                className="pt-5"
                style={{ borderTop: "1px solid rgba(255, 255, 255, 0.08)" }}
              >
                <div className="row g-0">
                  <div className="col-12">
                    <div className="row g-4">
                      <div className="col-lg-6 col-xl-4">
                        <div className="d-flex">
                          <div className="btn-xl-square bg-primary text-white rounded p-4 me-4">
                            <i className="fas fa-map-marker-alt fa-2x"></i>
                          </div>
                          <div>
                            <h4 className="text-white">Address</h4>
                            <p className="mb-0">180 Kentish Town Road London</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-4">
                        <div className="d-flex">
                          <div className="btn-xl-square bg-primary text-white rounded p-4 me-4">
                            <i className="fas fa-envelope fa-2x"></i>
                          </div>
                          <div>
                            <h4 className="text-white">Mail Us</h4>
                            <p className="mb-0">

jaaikaran@weplayfortheworld.org</p>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-4">
                        <div className="d-flex">
                          <div className="btn-xl-square bg-primary text-white rounded p-4 me-4">
                            <i className="fa fa-phone-alt fa-2x"></i>
                          </div>
                          <div>
                            <h4 className="text-white">Telephone</h4>
                            <Link to="tel:+91 9869516206">
                            <p className="mb-0">+91 9869516206</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
