import React from "react";
import { Link } from "react-router-dom";
import Insta from "./Insta";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
  const options = {
    loop: true,
    items: 1,
    margin: 0,
    autoplay: true,
    dots: true,
    nav: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    navText: ['<i class="fa fa-angle-left fa-5x"></i>', '<i class="fa fa-angle-right fa-5x"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  return (
    <div>


<section className="homecarousel-testimonials">
      <OwlCarousel {...options} className="owl-carousel" id="homecarousel-customers-testimonials">
        <div className="homecarousel-item">
          <div className="homecarousel-slider-caption">
            {/* <div className="homecarousel-top-caption">
              <h2>    Donate Now,<br></br>Save the world...</h2>
            </div>
            */}
          </div>
          <div className="homecarousel-shadow-effect">
            <img
              className="homecarousel-pull-right"
              src="/assets/img/homebanner.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="homecarousel-item">
          <div className="homecarousel-slider-caption homecarousel-slider-caption2">
          {/* <div className="homecarousel-top-caption">
              <h2>    Donate Now,<br></br>Save the world...</h2>
            </div> */}
          </div>
          <div className="homecarousel-shadow-effect">
            <img
              className="homecarousel-pull-right"
              src="/assets/img/homebanner1.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="homecarousel-item">
          <div className="homecarousel-slider-caption homecarousel-slider-caption2">
          {/* <div className="homecarousel-top-caption">
              <h2>    Donate Now,<br></br>Save the world...</h2>
            </div> */}
          </div>
          <div className="homecarousel-shadow-effect">
            <img
              className="homecarousel-pull-right"
              src="/assets/img/homebanner2.jpg"
              alt=""
            />
          </div>
        </div>
        
      </OwlCarousel>
    </section>

      
      {/* <section id="hero" className="hero section dark-background">
        <img
          src="/assets/img/homebanner.jpg"
          alt="Hero Background"
          data-aos="fade-in"
          style={{ objectFit: "cover" }}
        />
        <div className="container">
          <h2 data-aos="fade-up" data-aos-delay="100">
            Donate Now,
            <br /> Save the world...
          </h2>
         
        </div>
      </section> */}

      <div className="container-fluid service py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp home-heading"
            data-wow-delay="0.2s"
          >
            {/* <h4 className="text-primary">Donate, for every child</h4> */}
            <h1 className="display-4 mb-4">Donate, to make a change</h1>
            <p className="mb-0">
            We Play for the World transforms the passion for sport into positive change. 🤝
            Every kit purchase supports charities around the world.
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/donatehome/donate1.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-users fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    Your generous donations directly support our initiatives aimed at providing essential resources and opportunities for underprivileged children.
                    </Link>
                    {/* <p className="mb-4">
                      Famine confirmed in Sudan’s North Darfur, confirming UN
                      agencies worst fears
                    </p> */}
                    <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/donatehome/donate2.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-hospital fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    We develop and implement community-based programs that support children’s overall well-being, including mentoring.
                    </Link>
                    {/* <p className="mb-4">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Perspiciatis, eum!
                    </p> */}
                    <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/donatehome/donate3.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-car fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home  h4 mb-4">
                    In times of crisis, your support enables us to offer immediate aid to children affected by natural disasters, conflicts, and other emergencies
                    </Link>
                    {/* <p className="mb-4">
                      Conflict in Sudan is pushing already vulnerable children
                      deeper into hardship
                    </p> */}
                    <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img
                    src="/assets/img/donatehome/donate4.jpg"
                    className="img-fluid rounded-top w-100"
                    alt=""
                  />
                  {/* <div className="service-icon p-3">
                    <i className="fa fa-home fa-2x"></i>
                  </div> */}
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <Link href="#" className="d-inline-block donate-home h4 mb-4">
                    Donations help us build and maintain shelters for homeless children, providing them with a safe and nurturing environment.
                    </Link>
                    
                    <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>

     
     
<div className="Instagram">
<Insta/>
</div>
      



      <div className="container-fluid testimonial pb-3">
        <div className="container pt-3">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp home-heading"
            data-wow-delay="0.2s"
          >
            <h4 className="text-primary">Testimonial</h4>
            <h1 className="display-4 mb-1">What Our Donors Say
            </h1>
            <p className="mb-0">
            "Donating to this cause has been one of the best decisions I've made. The foundation's work in Ukraine is both impactful and necessary. I encourage anyone who can to contribute to this vital mission.
            </p>
          </div>
          
        </div>
      </div>
      <div className="container-fluid service pb-5">
      <div className="container "  id="home-galleys">
        <div
          className="text-center mx-auto pb-5 wow fadeInUp home-heading"
          data-wow-delay="0.2s"
        >
          {/* <h4 className="text-primary">Donate, for every child</h4> */}
          {/* <h1 className="display-4 mb-4">Donate, to make a change</h1> */}
          {/* <p className="mb-0">
          We Play for the World transforms the passion for sport into positive change. 🤝
          Every kit purchase supports charities around the world.
          </p> */}
        </div>
        <div className="row g-4 justify-content-center">
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
           
          >
            <div className="service-item">
              <div className="service-img" >
                <img
                  src="/assets/img/teamimg-1.jpeg"
                  className="img-fluid rounded-top w-100"
                  alt=""
                  style={{borderRadius:"10px"}}
                />
                {/* <div className="service-icon p-3">
                  <i className="fa fa-users fa-2x"></i>
                </div> */}
              </div>
              {/* <div className="service-content p-4">
                <div className="service-content-inner">
                  <Link href="#" className="d-inline-block donate-home h4 mb-4">
                  Your generous donations directly support our initiatives aimed at providing essential resources and opportunities for underprivileged children.
                  </Link>
                 
                  <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                    Read More
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src="/assets/img/teamimg-2.jpeg"
                  className="img-fluid rounded-top w-100"
                  alt=""
                  style={{borderRadius:"10px"}}
                />
                {/* <div className="service-icon p-3">
                  <i className="fa fa-hospital fa-2x"></i>
                </div> */}
              </div>
              {/* <div className="service-content p-4">
                <div className="service-content-inner">
                  <Link href="#" className="d-inline-block donate-home h4 mb-4">
                  We develop and implement community-based programs that support children’s overall well-being, including mentoring.
                  </Link>
                 
                  <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                    Read More
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src="/assets/img/teamimg-3.jpeg"
                  className="img-fluid rounded-top w-100"
                  alt=""
                  style={{borderRadius:"10px"}}
                />
                {/* <div className="service-icon p-3">
                  <i className="fa fa-car fa-2x"></i>
                </div> */}
              </div>
              {/* <div className="service-content p-4">
                <div className="service-content-inner">
                  <Link href="#" className="d-inline-block donate-home  h4 mb-4">
                  In times of crisis, your support enables us to offer immediate aid to children affected by natural disasters, conflicts, and other emergencies
                  </Link>
                  
                  <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                    Read More
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div className="service-item">
              <div className="">
                {/* <img
                  src="/assets/img/donatehome/donate4.jpg"
                  className="img-fluid rounded-top w-100"
                  alt=""
                  style={{borderRadius:"10px"}}
                /> */}
                <video width="320" height="300"autoPlay loop muted style={{objectFit:"cover",borderRadius:"10px"}}>
  <source src="/assets/img/homevideo.mp4" type="video/mp4" />

</video>
                {/* <div className="service-icon p-3">
                  <i className="fa fa-home fa-2x"></i>
                </div> */}
              </div>
              {/* <div className="service-content p-4">
                <div className="service-content-inner">
                  <Link href="#" className="d-inline-block donate-home h4 mb-4">
                  Donations help us build and maintain shelters for homeless children, providing them with a safe and nurturing environment.
                  </Link>
                  
                  <Link className="btn btn-primary rounded-pill py-2 px-4" href="#">
                    Read More
                  </Link>
                </div>
              </div> */}
            </div>
          </div>

         
        </div>
      </div>
    </div>

      <div className="homepage-donate mt-2">
     <div className="col-lg-8">
       <img 
       className="homeservice-img-1"
       src="/assets/img/donatehome/home6.jpg"/>
      </div>
      <div className="col-lg-4 homeservice-content-1">
       
        <p> Every donation, no matter the size, makes a significant difference. Stand with the people of Ukraine in their time of need. Together, we can provide hope and relief to those suffering from the impact of this devastating conflict.
        </p>
        <div><Link to="/DonationPage"> <button className="btn btn-primary">Donate Now</button> </Link></div>
       
       
      </div>
     </div>
    </div>
  );
};

export default Home;
